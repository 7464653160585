@import "./elements/variables.scss";
@import "./_custom.scss";
@import "./elements/mixins.scss";
@import "./elements/global.scss";
@import "./elements/utilities.scss";
@import "./elements/navbar.scss";
@import "./elements/cards.scss";
@import "./elements/footer.scss";
@import "./elements/login.scss";
@import "./elements/sidemenu.scss";
@import "./elements/checkbox.scss";
@import "./elements/darkMode.scss";