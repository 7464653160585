.sidebar {
    min-height: calc(100vh - 56px);
    padding: 0.5rem 0;
    z-index: 2;

    .btn-group {
        .nav-link {
            display: block;
            width: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            color: rgba(#ffffff, 0.5);
            text-align: left;
            cursor: pointer;
            padding-right: 1rem;
            padding-left: 1rem;

            &:hover {
                color: rgba(#ffffff, 0.75);
            }
        }
    }

    .dropright {
        .dropdown-menu {
            background: var(--dark);
            margin: 0;
            border-radius: 0;
            border: none;
            padding: 0.8rem 0;

            .dropdown-item {
                color: rgba(#ffffff, 0.5);
                transition: background-color 0.2s ease-in-out;

                &:hover {
                    background-color: #212529;
                    transition: opacity 0.7s;
                }
            }
        }

        .dropdown-toggle {
            &:after {
                width: 1rem;
                height: 1.4rem;
                text-align: center;
                vertical-align: middle;
                border: 0;
                font-weight: 900;
                content: '\F105';
                font-family: 'Font Awesome 5 Free';
                position: absolute;
                top: 50%;
                right: 15px;
                margin-top: -11px;
            }
        }

        &.show {
            background: var(--dark);
        }
    }

    .dropdown-submenu {
        position: relative;

        .nav-link {
            padding: 0 !important;

        }

        a {
            cursor: pointer;
        }

        i {
            float: right;
            color: #9a9d8d;
        }
    }

    .dropdown-submenu>.dropdown-menu {
        top: 0;
        left: calc(100% + 1px);
        margin-top: -6px;
        margin-left: -1px;
        -webkit-border-radius: 0 6px 6px 6px;
        -moz-border-radius: 0 6px 6px;
        border-radius: 0 6px 6px 6px;
        position: absolute;
    }

    .dropdown-submenu:hover>.dropdown-menu {
        display: block;
    }

    .dropdown-submenu>a:after {
        display: block;
        content: " ";
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #ccc;
        margin-top: 5px;
        margin-right: -10px;
    }

    .dropdown-submenu:hover>a:after {
        border-left-color: #fff;
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left>.dropdown-menu {
        left: -100%;
        margin-left: 10px;
        -webkit-border-radius: 6px 0 6px 6px;
        -moz-border-radius: 6px 0 6px 6px;
        border-radius: 6px 0 6px 6px;
    }
}

.nav-search {
    input {
        background: var(--dark);
        border-color: var(--dark);
        color: rgba(#ffffff, 0.5);

        &:focus {
            color: rgba(#ffffff, 0.5);
            background-color: var(--dark);
            border-color: var(--dark);
            box-shadow: 0 0 0 0.2rem rgba(var(--dark), 0.3);
            z-index: 0 !important;

            +.input-group-append {
                button {
                    box-shadow: 0 0 0 3rem rgba(var(--dark), 0.3);
                }
            }
        }
    }

    .input-group-append {
        button {
            border-left-color: rgba(#ffffff, 0.5);

            i {
                color: var(--primary);
            }
        }
    }
}

@media(min-width: 768px) {
    .sidebar {

        .btn-group,
        .nav-item {
            .nav-link {
                padding: 1rem;
                padding-right: 2rem;
            }
        }
    }
}

@media(max-width: 575px) {
    body {
        &:not(.sidebar-toggled) {
            overflow: hidden;
        }
    }

    .sidebar {
        display: none;
        overflow: hidden;
        position: absolute;
        top: $navbar-base-height;
        //bottom: $sticky-footer-height;
        left: 0;
        width: 90%;
        max-height: calc(100vh - 56px);
        overflow-y: auto;
        z-index: 10;

        .dropright {
            display: flex;
            flex-direction: column;

            .dropdown-toggle {
                &:after {
                    content: "\f107";
                    top: 9px;
                    margin-top: 0;
                    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                }
            }

            &.show {
                .dropdown-toggle {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }

            .dropdown-menu {
                padding-top: 0;

                .dropdown-item {
                    white-space: normal;
                }

                &.show {
                    position: relative !important;
                    top: 0 !important;
                    right: 0 !important;
                    left: 0 !important;
                    bottom: 0 !important;
                    transform: translate3d(0, 0, 0) !important;
                }
            }
        }
    }
}