.checkbox {
    position: relative;
    label {
        line-height: 20px;
        .cb {
            display: block;
            float: left;
            width: 20px;
            height: 20px;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            margin-right: 5px;
            text-align: center;
            transition: background-color 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
            i {
                font-size: 14px;
                color: #ffffff;
                vertical-align: top;
                margin-top: 2px;
                display: none;
            }
        }
        input {
            visibility: hidden;
            position: absolute;
            &:checked + .cb {
                background-color: $primary;
                border-color: $primary;
                i {
                    display: inline-block;
                }
            }
        }
    }
}