.login-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    max-width: 600px;
    margin: auto;
}

.form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
    .checkbox {
        font-weight: 400;
        label {
            margin: 0;
        }
    }

    .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
        margin-bottom: 10px;
        &:focus {
            z-index: 2;
        }
    }
}