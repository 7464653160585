footer.sticky-footer {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: $sticky-footer-height;
  background-color: $gray-200;
  .copyright {
    line-height: 1;
    font-size: 0.8rem;
  }
  @media (min-width: 420px) {
    width: 100%;
  }
}

body.sidebar-toggled {
  footer.sticky-footer {
    width: 100%;
  }

}
