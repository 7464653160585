// Global styling for this template

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;

}

#wrapper {
  display: flex;

  #content-wrapper {
    overflow-x: hidden;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: $sticky-footer-height+20;
  }
}

// Fixed Nav Option
body.fixed-nav {
  #content-wrapper {
    margin-top: $navbar-base-height;
    padding-left: $sidebar-collapsed-width;
  }

  &.sidebar-toggled {
    #content-wrapper {
      padding-left: 0;
    }
  }

  @media(min-width: 768px) {
    #content-wrapper {
      padding-left: $sidebar-base-width;
    }

    &.sidebar-toggled {
      #content-wrapper {
        padding-left: $sidebar-collapsed-width;
      }
    }
  }
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: $white;
  background: fade-out($gray-800, .5);
  line-height: 46px;
  z-index: 1000;

  &:focus,
  &:hover {
    color: white;
  }

  &:hover {
    background: $gray-800;
  }

  i {
    font-weight: 800;
  }
}

.text_widget {
  textarea {
    visibility: hidden;
  }
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;

  table {
    min-width: 600px;
  }

  td {
    &:last-child {
      min-width: 90px;
    }
  }
}

@media(max-width: 767px) {
  .table-wrapper {
    th {
      &:last-child {
        i {
          display: none;
        }
      }
    }

    .item-actions {
      span {
        display: none;
      }
    }
  }
}

#simplePopupPreloader {
  border-top-color: #447100 !important;
}

#simplePopupPreloaderAfter {
  border-top-color: #86bb00 !important;
}

#simplePopupPreloaderBefore {
  border-top-color: #c3d824 !important;
}

.dataTables_paginate {
  &.paging_simple_numbers {
    margin-bottom: 2rem !important;

    #DataTables_Table_0_previous {
      font-weight: 400;
      text-align: center;
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
      color: #212529;
      margin-right: 0.5rem;

      &:hover {
        text-decoration: none;

      }
    }

    #DataTables_Table_0_next {
      font-weight: 400;
      text-align: center;
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
      color: #212529;
      margin-left: 0.5rem;

      &:hover {
        text-decoration: none;

      }
    }

    span {
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        background: #343a40;
        padding: 0.25rem 0.5rem;
        color: #fff;

        &.current {
          background-color: #548b31;
        }

        &:hover {
          text-decoration: none;
          background-color: #548b31;
        }
      }
    }
  }


}

.filemenager {
  font: 900 22px "Font Awesome 5 Free" !important;
  color: #92c01f !important;
}
.add-section{
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-left: 15px;
  padding-right: 15px;
}
// .filemanager-add-btn {
//   margin-left: auto;
//   margin-right: 10px;
//   margin-top: 10px;
// }

// .filemanager-close-btn {
//   position: absolute;
//   top: -20px;
//   right: -15px;
//   background-color: #dc3545 !important;
//   opacity: 1;
//   line-height: inherit;
//   padding-top: 0.4rem !important;
//   padding-bottom: 0.6rem !important;
//   padding-right: 1.1rem !important;
//   padding-left: 1.1rem !important;
//   border-radius: 50%;
//   color: #fff !important;
//   @include transition(all .3s ease-in);

//   &:hover {
//     color: #dc3545 !important;
//     opacity: 1;
//     background: #fff !important;
//   }

//   &:focus {
//     color: #dc3545 !important;
//     opacity: 1;
//     background: #fff !important;
//   }
// }

#filemanager-confirm {
  display: none;
}

.filemanager-confirmation0 {
  text-align: center;

  p {
    margin-bottom: 3rem;
  }
}