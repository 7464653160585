body {
  &.dark-mode{
      // background: #101010;
      // color: #fff;
      .dark-mode-toggle{
          
          .fa-moon{
              color: #fff;
          }
      }
      $primary: #1f3412;// #284217; //;#31511d
      $dm-bg-darken-1: #101010;
      $dm-bg-darken-2: #080808;
      $dm-bg-body: #181818;
      $dm-bg-lighten-1: #282828;
      $dm-bg-lighten-2: #303030;
      $dm-bg-lighten-3: #383838;
      $dm-bg-lighten-4: #404040;
      $dm-bg-lighten-5: #484848;
      $dm-bg-lighten-6: #505050;

      background-color: $dm-bg-body !important;
      color: #fff !important;

      .login-wrapper {
          background-color: $dm-bg-lighten-1;
      }
      .form-control{
          background-color: $dm-bg-lighten-2;
          color: rgba(#fff, 0.9);
          
          border-color: $dm-bg-lighten-4;
      }
      .alert-danger{
          background: $dm-bg-lighten-4 !important;
          color: rgba(#fff, 0.9);
          
          border-color: $dm-bg-lighten-6;
      }
      nav.bg-primary {
          background: $primary !important;
          
      }
      .btn-primary, .btn-success {
          background: $primary !important;
          border-color: $dm-bg-lighten-3;
          &:hover{
              background: lighten($primary, 5%) !important;
          }
      }
      .btn-dark{
          background-color: $dm-bg-lighten-3;
          border-color: $dm-bg-lighten-4;
          &:hover{
              background-color: $dm-bg-lighten-4;
              border-color: $dm-bg-lighten-6;
          }
      }
      .btn-danger{
          background-color: $dm-bg-darken-1;
          border-color: $dm-bg-lighten-1;
          &:hover{
              background-color: $dm-bg-lighten-1;
              border-color: $dm-bg-lighten-3;
          }
      }
      .container-fluid, .container{
          .nav-pills .nav-link{
              background-color: $dm-bg-lighten-2;
              color: rgba(#fff, 0.9);
          }
          .nav-pills .nav-link.active, .nav-pills .nav-link:hover{
              background-color: $dm-bg-lighten-4;
          }
          .table{
              background-color: $dm-bg-lighten-2;
              th, td{border-color: $dm-bg-lighten-3 !important;}
              thead {
                  background-color: $dm-bg-lighten-1;
                  color: #fff !important; 
                 

              }
              tbody{
                  color: #fff !important;
              }
          }
          .page-item.disabled .page-link{
              background-color: $dm-bg-darken-1;
              border-color: $dm-bg-lighten-2;
              color: rgba(#fff, 0.5);
          }
          
          .page-item.active .page-link {
              background-color: $dm-bg-lighten-4;
              border-color: $dm-bg-lighten-6;
              color: #fff;
          }
         .page-item .page-link {
              background-color: $dm-bg-lighten-2;
              border-color: $dm-bg-lighten-4;
              color: rgba(#fff, 0.7);
              &:hover{
                  background-color: $dm-bg-lighten-3;
                  border-color: $dm-bg-lighten-5;
                  color: rgba(#fff, 0.9);
              }
          }
          input, select, textarea{
              background: $dm-bg-lighten-2;
              color: rgba(#fff, 0.8);
              border: 1px solid $dm-bg-lighten-4;
              &::-webkit-input-placeholder {
                  
                  color: rgba(#fff, 0.5) !important;
              }
          
              &::-moz-placeholder {
                  /* Firefox 19+ */
                  color: rgba(#fff, 0.5) !important;
              }
          
              &:-ms-input-placeholder {
                  /* IE 10+ */
                  color: rgba(#fff, 0.5) !important;
              }
          
              &:-moz-placeholder {
                  /* Firefox 18- */
                  color: rgba(#fff, 0.5) !important;
              }
          
              &::placer {
                  color: rgba(#fff, 0.5) !important;
              }
          }
      }
      .modal-header, .modal-footer{
          border-color: $dm-bg-lighten-6;
      }
      .btn-secondary{
          background-color: $dm-bg-lighten-2;
          border-color: $dm-bg-lighten-4;
          &:hover{
              background-color: $dm-bg-lighten-4;
              border-color: $dm-bg-lighten-6;
          }
      }
    //   .filemanager-close-btn{
    //       background: $dm-bg-lighten-4 !important;
    //       color: rgba(#fff, 0.9);
    //       i {
    //           color: rgba(#fff, 0.9);
    //       }
    //       &:hover{
    //           background: $dm-bg-lighten-6 !important;
    //           i{
    //               color: rgba(#fff, 1);
    //           }
    //       }
    //   }
      .cms-footer{
          background: $dm-bg-body;
          border-color: $dm-bg-lighten-6;
      }
      .checkbox label .cb{
          background: $dm-bg-lighten-2;
          border-color: $dm-bg-lighten-4;
      }
      .checkbox label input:checked + .cb{
          background: $primary;
          border-color: $dm-bg-lighten-4;
      }
      .dropdown-menu{
          background: $dm-bg-lighten-2;
          border-color: $dm-bg-lighten-4;
          color: rgba(#fff, 0.9);
      }
      .dropdown-divider {
          border-color: $dm-bg-lighten-4;
      }
      .dropdown-item{
          color: rgba(#fff, 0.9);
          &:hover, &:focus{
              background-color: $dm-bg-lighten-4;
          }
      }
      .navbar-nav .dropdown-submenu:hover > .dropdown-menu{
          background-color: $dm-bg-lighten-4;
          .dropdown-item{
              &:hover, &:focus{
                  background-color: $dm-bg-lighten-6;
              }
          }
      }
      .entity-select{
          button{
              background: $dm-bg-lighten-2;
              border: 1px solid $dm-bg-lighten-4;
              color: rgba(#fff, 0.9);
          }
      }
      footer.sticky-footer{
          background-color: $dm-bg-darken-1;
          border-top: 1px solid $dm-bg-lighten-1;
          .container, .container-fluid{
              background-color: $dm-bg-darken-1;
          }
      }
      .modal-content{
          background: $dm-bg-lighten-1;
      }
      .upload-form{
          .dropzone{
              background: $dm-bg-lighten-3;
          }
      }
      .close{
          color: rgba(255,255,255, 0.6);
          text-shadow: 0 1px 0 #000;
          &:hover{
              color: rgba(255, 255,255, 1);
          }
      }
      .alert-success{
          background: $dm-bg-lighten-4;
          color: rgba(255,255,255,0.9);
      }
      .alert{
          border: $dm-bg-lighten-3;
          background: $dm-bg-lighten-4;
          color: rgba(255,255,255,0.9);
      }
      .cke{
          border-color: $dm-bg-lighten-1 !important;
          background-color: $dm-bg-lighten-3 !important;
          .cke_button_icon{
              background-image: url('../../../images/icons/ckeditor_flat_white.png') !important;
          }
          .cke_button_label{
              color: rgba(255,255,255, 0.9) !important;
          }
          .cke_button_on{
              background-color: $dm-bg-lighten-4 !important;
              border-color: $dm-bg-lighten-6 !important;
          }
          .cke_button_off{
              background-color: $dm-bg-lighten-3 !important;
              &:hover, &:active, &:focus{
                  background-color: $dm-bg-lighten-4 !important;
                  border-color: $dm-bg-lighten-6 !important; 
              }
              
          }
          a.cke_combo_button{
              background-color: $dm-bg-lighten-1;    
              border-color: $dm-bg-lighten-1;
              &:hover, &:active, &:focus{
                  background-color: $dm-bg-lighten-4 !important;
                  border-color: $dm-bg-lighten-6 !important;  
              }
              .cke_combo_text{
                  color: rgba(255,255,255,0.9);
              }
              .cke_combo_open{
                  .cke_combo_arrow{
                      border-top: 3px solid rgba(255,255,255,0.9);
                  }
              }
          }
          .cke_inner {
              .cke_top{
                  background-color: $dm-bg-lighten-1 !important;
                  border-color: $dm-bg-lighten-3 !important;
              }
              .cke_bottom{
                  background-color: $dm-bg-lighten-1 !important;
                  border-color: $dm-bg-lighten-3 !important;
              }
          }
      }
  }
 
}