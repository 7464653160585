.navbar-nav {

  .form-inline .input-group {
    width: 100%;
  }

  .nav-item {
    &.active {
      .nav-link {
        color: $white;
      }
    }

    &.dropdown {
      &.no-arrow {
        .dropdown-toggle::after {
          display: none;
        }
      }
    }

    .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;

      &:focus {
        // remove outline for Safari and Firefox
        outline: none;
      }

      .badge {
        position: absolute;
        margin-left: 0.75rem;
        top: 0.3rem;
        font-weight: 400;
        font-size: 0.5rem;
      }
    }
  }

  @media(min-width: 768px) {

    .form-inline .input-group {
      width: auto;
    }

  }

  .dropdown-submenu {
    position: relative;

    a {
      cursor: pointer;
    }

    i {
      float: right;
      color: #548b31;
    }
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: calc(100% + 1px);
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
  }

  .dropdown-submenu:hover>.dropdown-menu {
    display: block;
  }

  .dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  .dropdown-submenu:hover>a:after {
    border-left-color: #fff;
  }

  .dropdown-submenu.pull-left {
    float: none;
  }

  .dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
  }

}

.nav-pills {
  .nav-link {
    background-color: $gray-200;

    &.active,
    &:hover {
      background-color: $gray-500;
    }
  }
}

.sidebar {
  min-width: 225px;
  width: 225px;
  background-color: $gray-900;
  min-height: calc(100vh - #{$navbar-base-height});
  /*   @include sidebar-icons; */
  transition: min-width 0.25s ease-in-out, width 0.25s ease-in-out, transform 0.25s ease-in-out;

  .nav-item {
    .nav-link {
      color: fade-out($white, 0.5);

      &:active,
      &:focus,
      &:hover {
        color: fade-out($white, 0.25);
      }
    }
  }

  &.toggled {
    overflow: hidden;
    transform: translate(-100%, 0);

    /*     height: 0; */
    @include media-breakpoint-up(sm) {
      min-width: 0;
      width: 0;
    }

    /*     .nav-item,
    .btn-group {
      white-space: nowrap;
    } */
  }
}

// Fixed Nav Option
// Add .fixed-top class to top .navbar-nav and to .sidebar - add .fixed-nav to body

.sidebar.fixed-top {
  top: $navbar-base-height;
  height: calc(100vh - #{$navbar-base-height});
  overflow-y: auto;
}

.navbar {
  .navbar-brand {
    .dimedia-logo {
      max-height: 25px;
      max-width: auto;
    }
  }
}